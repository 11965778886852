<template>
    <section class="section">
        <v-sheet id="scrolling-techniques-7" class="overflow-y-auto">
            <v-container class="mt-10">
                <UserFilters @setUserQuery="getUserList" v-model="isFiltersActive"> </UserFilters>

                <div id="user-list" class="mt-8">
                    <!-- actions -->
                    <div class="d-flex">
                        <!-- search -->
                        <v-text-field
                            expanded
                            v-model="search"
                            @change="searchUsers()"
                            :placeholder="$t('Search')"
                            outlined
                            hide-details
                            dense
                            :prepend-inner-icon="icons.mdiMagnify"
                            class="user-search me-3 mb-4">
                        </v-text-field>

                        <v-btn @click.stop="isFiltersActive = !isFiltersActive" outlined color="grey-light">
                            <v-icon color="grey">
                                {{ icons.mdiFilterVariant }}
                            </v-icon>
                        </v-btn>
                    </div>

                    <!-- table -->
                    <v-card class="mt-8">
                        <template>
                            <v-data-table
                                show-select
                                calculate-widths
                                :items-per-page="5"
                                :footer-props="{
                                    'items-per-page-options': [5, 10, 15, 20],
                                    'items-per-page-text': $t('RowsPerPage'),
                                }"
                                :header-props="{ 'sort-by-text': $t('SortBy') }"
                                v-model="selectedUsers"
                                :headers="tableColumns"
                                :items="userListTable"
                                :server-items-length="totalUserListTable"
                                :loading="isLoading"
                                fixed-header
                                height="500">
                                <!-- name -->
                                <template #[`item.name`]="{ item }">
                                    <div class="d-flex align-center">
                                        <v-avatar
                                            :color="item.avatar ? '' : 'primary'"
                                            :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
                                            size="32">
                                            <v-img
                                                v-if="resolveUserPicture(item.picture)"
                                                :src="resolveUserPicture(item.picture)"></v-img>
                                            <span v-else class="font-weight-medium">{{ avatarText(item.name) }}</span>
                                        </v-avatar>
                                        <div class="d-flex flex-column ms-3">
                                            <router-link
                                                :to="{ name: 'UserProfile', params: { id: item.id } }"
                                                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none">
                                                {{ item.name }}
                                            </router-link>
                                        </div>
                                    </div>
                                </template>

                                <!-- email -->
                                <template #[`item.email`]="{ item }">
                                    <span class="font-weight-medium text--primary">{{ item.email }}</span>
                                </template>

                                <!-- role -->
                                <template #[`item.role`]="{ item }">
                                    <span class="text-capitalize font-weight-medium text--primary">{{
                                        item.role
                                    }}</span>
                                </template>
                                <!-- parks -->
                                <template v-slot:item.parkingLots="{ item }">
                                    <span v-if="item.parkingLots.length > 0">{{ item.parkingLots[0].name }}</span>
                                    <v-tooltip bottom v-if="item.parkingLots.length > 1">
                                        <template v-slot:activator="{ on, attrs }">
                                            <span class="info--text" v-bind="attrs" v-on="on" style="cursor: default"
                                                >{{ limitParkText(item.parkingLots) }}
                                                {{ $tc('ParkTooltip', item.parkingLots.slice(1).length) }}</span
                                            >
                                        </template>
                                        <span>{{
                                            item.parkingLots
                                                .slice(1)
                                                .map((entry) => entry.name)
                                                .join(', ')
                                        }}</span>
                                    </v-tooltip>
                                </template>
                                <!-- actions -->
                                <template #[`item.actions`]="{ item }">
                                    <v-btn @click="$router.push({ name: 'UserProfile', params: { id: item.id } })" icon>
                                        <v-icon large>{{ icons.mdiChevronRight }}</v-icon>
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </template>
                    </v-card>
                </div>
            </v-container>
        </v-sheet>
        <v-app-bar height="80" absolute color="white" elevate-on-scroll scroll-target="#scrolling-techniques-7">
            <v-container class="d-flex">
                <v-toolbar-title>
                    <p class="d-none d-md-flex d-lg-flex d-xl-flex text-h5 font-weight-semibold black--text mt-1">
                        {{ $t('Users') }}
                    </p>
                    <p class="text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none">
                        {{ $t('Users') }}
                    </p>
                </v-toolbar-title>
                <v-spacer></v-spacer>

                <v-btn
                    @click="$router.push({ name: 'UserProfile', params: { id: 'add' } })"
                    color="primary"
                    class="mx-3">
                    <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="white">
                        {{ icons.mdiPlus }}
                    </v-icon>
                    <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="white">
                        {{ icons.mdiPlus }}
                    </v-icon>
                    <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('AddUser') }}</span>
                </v-btn>
                <v-dialog v-if="selectedUsers.length > 0" transition="dialog-top-transition" max-width="600">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="error" v-bind="attrs" v-on="on">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="error">
                                {{ icons.mdiTrashCanOutline }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="error">
                                {{ icons.mdiTrashCanOutline }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Delete') }}</span>
                        </v-btn>
                    </template>
                    <template v-slot:default="dialog">
                        <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/delete.png"></v-img>
                        <v-card>
                            <v-card-text class="text-center">
                                <p class="title font-weight-semibold black--text mt-12">{{ $t('Alerts.Sure') }}</p>
                            </v-card-text>
                            <v-card-text class="text-center">
                                <p v-if="selectedUsers.length === 1" class="body-2">
                                    {{ $t('Alerts.DeleteUser') }}
                                    <strong class="black--text">{{
                                        selectedUsers.map((entry) => entry.name).join(', ')
                                    }}</strong>
                                    ?<br />
                                    {{ $t('Alerts.Irreversible') }}
                                </p>
                                <p v-else class="body-2">
                                    {{ $t('Alerts.SureDeteteUsers') }}
                                    <strong class="black--text">{{
                                        selectedUsers.map((entry) => entry.name).join(', ')
                                    }}</strong>
                                    ?<br />
                                    {{ $t('Alerts.Irreversible') }}
                                </p>
                            </v-card-text>

                            <v-card-actions class="justify-end mt-4">
                                <v-btn @click="deleteSelectedUsers" color="error" class="px-5">
                                    {{ $t('Buttons.Delete') }}</v-btn
                                >
                                <v-btn text @click="dialog.value = false">{{ $t('Buttons.Cancel') }}</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
                <!-- ALERTAS DE SUCESSO E ERRO A ELIMINAR PARQUES-->
                <v-snackbar :timeout="2000" top v-model="isSuccess" color="white">
                    <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
                    <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
                    <p class="text-center black--text">{{ $t('Alerts.DeleteSuccessUser') }}</p>
                </v-snackbar>
                <v-snackbar :timeout="2000" top v-model="isError" color="white">
                    <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/failed.png"></v-img>
                    <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Error') }}</p>
                    <p class="text-center black--text">{{ $t('Alerts.ErrorMessage') }}</p>
                </v-snackbar>
            </v-container>
        </v-app-bar>
    </section>
</template>

<script>
    // eslint-disable-next-line object-curly-newline
    import store from '@/store';
    import {
        mdiAccountOutline,
        mdiChevronRight,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiExportVariant,
        mdiFileDocumentOutline,
        mdiFilterVariant,
        mdiMagnify,
        mdiPlus,
        mdiSquareEditOutline,
        mdiTrashCanOutline,
    } from '@mdi/js';
    import { onUnmounted, ref } from '@vue/composition-api';

    // sidebar
    import { avatarText } from '@core/utils/filter';
    import userStoreModule from '../userStoreModule';
    import useUsersList from './useUsersList';
    import UserFilters from '../user-filters/UserFilters.vue';
    import useParkList from '../../../views/parks/park-list/useParkList';

    export default {
        components: {
            UserFilters,
        },
        setup() {
            const { limitParkText } = useParkList();

            const {
                userListTable,
                tableColumns,
                searchQuery,
                roleFilter,
                planFilter,
                statusFilter,
                totalUserListTable,
                loading,
                options,
                userTotalLocal,
                selectedRows,
                fetchUsers,
                resolveUserRoleVariant,
                resolveUserRoleIcon,
                resolveUserStatusVariant,
                resolveUserTotalIcon,
                resolveUserPicture,
                deleteManyUsers,
            } = useUsersList();
            const isFiltersActive = ref(false);

            const isAddNewUserSidebarActive = ref(false);

            const roleOptions = [
                { title: 'Administrador', value: 'admin' },
                { title: 'CEO', value: 'ceo' },
                { title: 'Responsável', value: 'responsable' },
            ];

            const planOptions = [
                { title: 'Basic', value: 'basic' },
                { title: 'Company', value: 'company' },
                { title: 'Enterprise', value: 'enterprise' },
                { title: 'Standard', value: 'standard' },
            ];

            const statusOptions = [
                { title: 'Pending', value: 'pending' },
                { title: 'Active', value: 'active' },
                { title: 'Inactive', value: 'inactive' },
            ];

            return {
                userListTable,
                tableColumns,
                searchQuery,
                roleFilter,
                planFilter,
                statusFilter,
                totalUserListTable,
                roleOptions,
                planOptions,
                statusOptions,
                loading,
                options,
                userTotalLocal,
                isAddNewUserSidebarActive,
                selectedRows,
                avatarText,
                resolveUserRoleVariant,
                resolveUserRoleIcon,
                resolveUserStatusVariant,
                resolveUserTotalIcon,
                fetchUsers,
                resolveUserPicture,
                isFiltersActive,
                deleteManyUsers,
                limitParkText,

                // icons
                icons: {
                    mdiSquareEditOutline,
                    mdiFileDocumentOutline,
                    mdiDotsVertical,
                    mdiDeleteOutline,
                    mdiPlus,
                    mdiExportVariant,
                    mdiAccountOutline,
                    mdiMagnify,
                    mdiChevronRight,
                    mdiFilterVariant,
                    mdiTrashCanOutline,
                },
            };
        },
        created() {
            this.isLoading = true;
            this.$store.commit('app-user/resetUserQuery');
            this.fetchUsers();
            setTimeout(() => {
                this.isLoading = false;
            }, 1500);
        },
        data() {
            return {
                search: null,
                selectedUsers: [],
                isFiltersActive: false,
                isLoading: false,
                isSuccess: false,
                isError: false,
            };
        },
        methods: {
            getUserList() {
                this.isLoading = true;
                this.fetchUsers();
                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
            },
            //searchUsers
            searchUsers() {
                this.isLoading = true;
                this.$store.commit('app-user/setUserSearch', this.search);
                this.fetchUsers();
                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
            },

            deleteSelectedUsers() {
                this.isLoading = true;
                for (let i = 0; i < this.selectedUsers.length; i++) {
                    this.$store
                        .dispatch('app-user/deleteUser', this.selectedUsers[i].id)
                        .then((response) => {
                            this.isSuccess = true;
                            this.fetchUsers();
                            setTimeout(() => {
                                this.isLoading = false;
                            }, 1000);
                        })
                        .catch((error) => {
                            this.isError = true;

                            setTimeout(() => {
                                this.isLoading = false;
                            }, 1000);
                        });
                }
                this.selectedUsers = [];
            },
        },
    };
</script>

<style lang="scss">
    @import '@core/preset/preset/apps/user.scss';
</style>
